<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div class="timerbox">
        <div class="thetimer">
          <div class="time_kuang">{{ times.userDay }}</div>
          <div class="tk2">{{$t('S_L_6')}}</div>
          <div class="time_kuang">{{ times.userHr }}</div>
          <div class="tk2">{{$t('S_L_7')}}</div>
          <div class="time_kuang">{{ times.userMin }}</div>
          <div class="tk2">{{$t('S_L_8')}}</div>
          <div class="time_kuang">{{ times.userSec }}</div>
          <div class="tk2">{{$t('S_L_9')}}</div>
        </div>
        <div class="timer_remark">{{$t('CK_KS_1')}}</div>
      </div>
      <topFBar
        v-if="topFbarList.length > 0"
        :topList="topFbarList"
        :moren="moren"
      ></topFBar>
      <div class="topbox">
        <el-form ref="form" :model="queryParams" label-width="80px">
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('CK_KS_3')" prop="teamCode">
                <el-date-picker
                  v-model="thetime"
                  @change="checkTime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="——"
                  :start-placeholder="$t('CK_KS_4')"
                  :end-placeholder="$t('CK_KS_5')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <div class="searchbox">
                <el-button class="btn1" @click="chongzhi">{{$t('CK_KS_6')}}</el-button>
                <el-button class="btn2" @click="research">{{ $t('N_I_183') }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <el-table
          :data="tableData"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column align="center" prop="makerNum" :label="$t('CK_KS_7')"
            ><template slot-scope="scope">
              <span
                style="color: #48b2fd; cursor: pointer"
                @click="clickNum(scope.row)"
              >
                {{ scope.row.makerNum }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="giftNum" :label="$t('CK_KS_8')">
            <template slot-scope="scope">
              <span
                style="color: #48b2fd; cursor: pointer"
                @click="clickNum(scope.row)"
              >
                {{ scope.row.giftNum }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="enableDate"
            :label="$t('CK_KS_3')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="endDate"
            :label="$t('CK_KS_9')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="useDate"
            :label="$t('CK_KS_10')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="surplusDate"
            :formatter="checkSurplusDate"
            :label="$t('CK_KS_11')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="isReachVal"
            :label="$t('CK_KS_12')"
          ></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="research"
        />
      </div>
    </div>
    <el-dialog :title="$t('CK_KS_13')" :visible.sync="smShow" width="50%" center>
      <el-table
        :data="giftList"
        :header-cell-style="{ background: '#EEEEEE' }"
        :row-class-name="tableRowClassName"
        height="500px"
      >
        <el-table-column
          align="center"
          prop="memberCode"
          :label="$t('N_I_124')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="memberName"
          :label="$t('CK_KS_14')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="gradeName"
          :label="$t('CK_KS_15')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="awardsName"
          :label="$t('CK_KS_16')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="giftBuyNum"
          :label="$t('CK_KS_17')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="buyDate"
          :label="$t('CK_KS_18')"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import topFBar from "@/components/topFBar.vue";
import * as ene from "@/api/energy.js";
import { mapGetters } from "vuex";
import noticePopup from "@/components/noticePopup.vue";
export default {
  name: "Registration",
  components: {
    noticePopup,
    sidebarUserInfo,
    topBanner,
    pagination,
    topFBar,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      index: 70,
      moren: "energyWarehouse?id=0",
      topFbarList: [
        {
          name: this.$t('CK_KS_2'),
          path: "energyWarehouse?id=0",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
      },
      giftList: [],
      smShow: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      times: {
        userDay: "0",
        userHr: "00",
        userMin: "00",
        userSec: "00",
      },
      theSecond: "",
      countdownInterval: "",
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval); // 清除倒计时定时器
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.research();
  },
  methods: {
    // 倒计时事件
    countdown() {
      const that = this;
      // 获取后台接口返回的时间戳（以秒为单位）
      let surplusDate = that.theSecond;

      // 创建一个计时器
      that.countdownInterval = setInterval(() => {
        // 将时间戳减去一秒
        surplusDate--;

        if (surplusDate <= 0) {
          // 倒计时结束
          clearInterval(that.countdownInterval);
          return;
        }

        // 计算倒计时的天、小时、分钟和秒数
        let day = Math.floor(surplusDate / (60 * 60 * 24));
        let hour = Math.floor((surplusDate % (60 * 60 * 24)) / (60 * 60));
        let minute = Math.floor((surplusDate % (60 * 60)) / 60);
        let second = surplusDate % 60;
        // 将单个数字补零，以保持统一的格式
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;

        // 将倒计时的天、小时、分钟和秒数分别存储起来
        // 在页面上展示倒计时
        that.times.userDay = day;
        that.times.userHr = hour;
        that.times.userMin = minute;
        that.times.userSec = second;
      }, 1000);
    },
    checkSurplusDate(row) {
      let time = row.surplusDate;
      let format = this.formatTime(time); // 调用函数将秒数转换为天时分秒
      return format;
    },
    //将秒数转换为天时分秒
    formatTime(time) {
      let days = parseInt(time / (3600 * 24)); // 计算天数
      let hours = parseInt((time % (3600 * 24)) / 3600); // 计算小时数
      let minutes = parseInt((time % 3600) / 60); // 计算分钟数
      let seconds = time % 60; // 计算秒数
      return days + this.$t('S_L_6') + hours + this.$t('S_L_7') + minutes + this.$t('S_L_8') + seconds + this.$t('S_L_9');
    },
    checkTime(val) {
      this.queryParams.endStartDate = val[0];
      this.queryParams.endDate = val[1];
    },
    clickNum(row) {
      ene.getSiloDetail({ pkId: row.pkId }).then((res) => {
        this.giftList = res.rows;
        this.smShow = true;
      });
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        endStartDate: "",
        endDate: "",
      };
      this.thetime = "";
      this.research();
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      ene.getSiloList(this.queryParams).then((res) => {
        this.total = res.total;
        if (res.rows.length > 0) {
          this.theSecond = res.rows[0].surplusDate;
          res.rows.forEach((ele) => {
            ele.daystime = ele.surplusDate;
            let days = parseInt(ele.daystime / (3600 * 24)); // 计算天数
            let hours = parseInt((ele.daystime % (3600 * 24)) / 3600); // 计算小时数
            let minutes = parseInt((ele.daystime % 3600) / 60); // 计算分钟数
            let seconds = ele.daystime % 60; // 计算秒数
            ele.daystime =
            days + this.$t('S_L_6') + hours + this.$t('S_L_7') + minutes + this.$t('S_L_8') + seconds + this.$t('S_L_9');
          });
          this.tableData = res.rows;
          this.countdown();
        } else {
          this.tableData = res.rows;
          this.theSecond = 0;
          this.times.userDay = "0";
          this.times.userHr = "0";
          this.times.userMin = "0";
          this.times.userSec = "0";
          clearInterval(this.countdownInterval); // 清除倒计时定时器
        }
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}

.waibox {
  position: relative;
  .passkh {
    width: 88px;
    height: 88px;
  }
  .poimg {
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 5px;
    right: 15px;
    color: #ffffff;
  }
}

.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 0 !important;
  display: flex;
  .timerbox {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    padding: 75px 20px 17px 20px;
    .thetimer {
      display: flex;
      align-items: center;
      justify-content: center;
      .time_kuang {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 0px 0px;
        font-size: 48px;
        font-family: Anton-Regular, Anton;
        font-weight: 400;
        color: #c8161d;
        padding: 13px 24px;
      }
      .tk2 {
        font-size: 32px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin: 0 20px;
      }
    }
    .timer_remark {
      font-size: 18px;
      font-family: Anton-Regular, Anton;
      font-weight: 400;
      color: #000000;
      text-align: center;
      margin-top: 36px;
    }
  }
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
  }
}
</style>