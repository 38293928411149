import request from '@/util/request'

// 抗衰能量仓列表
export function getSiloList(data) {
  return request({
    url: '/member/api/silo/list',
    method: 'post',
    data
  })
}

//查询能量仓明细
export function getSiloDetail(data) {
    return request({
      url: '/member/api/silo/detail',
      method: 'post',
      data
    })
  }
