<template>
  <div class="bartopbox">
    <div
      class="levelList_i"
      :class="tabActive == item.path ? 'act' : ''"
      v-for="(item, index) in topList"
      :key="index"
      @click.prevent="handleLink(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "topFBar",
  props: {
    topList: {
      type: Array,
    },
    moren: {
      type: String,
    },
  },
  data() {
    return {
      tabActive: this.moren,
    };
  },
  methods:{
    handleLink(item) {
      this.tabActive = item.path;
        this.$router.push({
          path:item.path
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.bartopbox {
  align-items: center;
//   padding: 0px 15px;
  display: flex;
  margin-top: 20px;
//   background: rgba(204, 204, 204, 1);
  .levelList_i {
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    padding: 0 5px;
    min-width: 110px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: center;
    line-height: 44px;
  }
  .levelList_i:nth-child(1){
    margin-left: 0;
  }
}
.act {
  color: #FFFFFF;
  background: rgba(192, 31, 31, 1);

}

</style>