<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-03-03 17:39:11
-->
<template>
  <div class="main">
    <div class="main_r">
      <div class="menuList">
        <div v-for="item in menuList"
             :class="['menuList_i',actMeun==item.id?'actMeun':'']"
             :key="item.id"
             @click="changeTab(item.id)">{{ item.text }}</div>
      </div>
      <!-- 商品列表 -->
      <div class="goodList">
        <div class="good_i"
             v-for="item in goodList"
             :key="item.waresCode">

          <!-- 缺货无货售罄 -->
          <div v-show="item.preSaleStatus==3||item.isSale==1">
            <div class="cool"
                 v-if="item.coolLabelTarget != undefined">
              <img :src="item.coolLabelTarget.labelImage"
                   alt="">
            </div>
            <div class="motai">
            </div>
            <img class="goodImg"
                 :src="item.cover1"
                 alt="">
            <div class="goodBtm">
              <div class="disFlex">
                <div class="tit1 hui">{{ userInfo.currencyIcon }}{{ item.waresPrice }}</div>
                
              </div>
              <div class="disFlex">
                <div class="tit3">
                  <span class="qzbq hui_b"
                        v-if="item.prefixLabelTarget != undefined">{{ item.prefixLabelTarget.label }}</span>
                  <span>{{ item.waresName }}</span>
                </div>
              </div>
              <div class="disFlex">
                <div class="tit4 hui">
                  {{$t('CK_KS_102')}}:{{ item.sales |seles}}
                </div>
                <div class="tit4 hui" v-if="actMeun == 19"
                     style="margin-left:10px">
                  PV:{{ item.waresAchieve }}
                </div>
              </div>
              <!-- <img class="addCarImg"
                   :src="addCarImg3"
                   alt=""> -->
                   <div class="addCarImg1">
                            <img :src="addCarImg2" alt="">
                              <div>{{ $t('N_I_192') }}</div>
                            </div>
              <div class="md">
                <img v-for="ctem in item.sellingLabelList"
                     v-show="item.sellingLabelList"
                     :key="ctem.pkId"
                     :src="ctem.labelImage||''"
                     alt="">
              </div>
            </div>
          </div>
          <!-- 正常商品 -->
          <div v-show="item.preSaleStatus!=3&&item.isSale!=1"
               @click="goDetails(item)">
            <div class="cool"
                 v-if="item.coolLabelTarget != undefined">
              <img :src="item.coolLabelTarget.labelImage"
                   alt="">
            </div>
            <img class="goodImg"
                 :src="item.cover1"
                 alt="">
            <div class="goodBtm">
              <div class="disFlex">
                <div class="tit1">{{ userInfo.currencyIcon }}{{ item.waresPrice |toThousandthAndKeepDecimal }}</div>
              </div>
              <div class="disFlex">
                <div class="tit3">
                  <span class="qzbq"
                        v-if="item.prefixLabelTarget != undefined">{{ item.prefixLabelTarget.label }}</span>
                  <span>{{ item.waresName }}</span>
                </div>
              </div>
              <div class="disFlex">
                <div class="tit4">
                  {{$t('CK_KS_102')}}:{{ item.sales  |seles}}
                </div>
                <div class="tit4" v-if="actMeun == 19"
                     style="margin-left:10px">
                  PV:{{ item.waresAchieve |toThousandthAndKeepDecimal}}
                </div>
              </div>
              <!-- <img class="addCarImg"
                   v-show="isClick != item.waresCode"
                   :src="addCarImg1"
                   @click.stop='addToCar($event,item)'
                   alt="">
              <img class="addCarImg"
                   v-show="isClick== item.waresCode"
                   :src="addCarImg2"
                   alt=""> -->
                   <div class="addCarImg" @click.stop='addToCar($event,item)'>
                            <img :src="addCarImg1" alt="">
                              <div>{{ $t('N_I_192') }}</div>
                            </div>
              <div class="md">
                <img v-for="ctem in item.sellingLabelList"
                     v-show="item.sellingLabelList"
                     :key="ctem.pkId"
                     :src="ctem.labelImage||''"
                     alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-ball ref="ball"
                :duration="300"
                @after-enter="afterEnter">
        <div class="ccc"
             :style="{top:carTop + 'px'}"></div>
        <div slot="icon"
             class="dian"></div>
      </vue-ball>

    </div>
       <!-- 侧边栏 -->
       <Sidebar :showCar="true"
             @showCar="showCar"
             ref="sideBar"></Sidebar>
  </div>
</template>

<script>
import sidebarUserInfo from '@/components/sidebarUserInfo.vue'
import topBanner from '@/components/topBanner.vue'
import * as api from '@/api/goods.js'
import { Ball as vueBall } from '@hyhello/vue-ball'
import Sidebar from '@/components/Sidebar.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    sidebarUserInfo,
    topBanner,
    vueBall,
    Sidebar,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  filters:{
        seles(value){
          
          if(value>999){
            return 999 + '+'
          }else{
            return value
          }
            
        }
    },
  data() {
    return {
      goodList: [],
      actMeun: 18,
      addCarImg1: require('@/assets/images/cart.png'),
      addCarImg2: require('@/assets/images/cart1.png'),
      // addCarImg2: require('@/assets/images/addCar2.png'),
      addCarImg3: require('@/assets/images/addCar3.png'),
      menuList: [
      {
          text: this.$t('ENU_ORDER_T_18'),
          id: 18,
        },
        {
          text: this.$t('ENU_SPECIAL_A_19'),
          id: 19,
        },
        // {
        //   text: '我的礼包',
        //   id: 200,
        // },
      ],
      isClick: false,
      carTop: 0,
      heartBeat: false,
    }
  },

  mounted() {
    this.getAllGoods()
    this.carTop = this.$children[1].$refs.shoppCar.getBoundingClientRect().top
  },
  methods: {
    showCar() {
      this.$refs.sideBar.getCarList(this.actMeun)
    },
    changeTab(id) {
      this.actMeun = id
      this.$store.dispatch('SpecialQuantity',id)
      this.getAllGoods()
    },
    getAllGoods() {
      api
        .getAllGoods({
          specialArea: this.actMeun,
        })
        .then((res) => {
          this.goodList = res.data
          this.goodList.forEach((item) => {
            if (item.waresName.length > 13) {
              item.waresName = item.waresName.substring(0, 13) + '...'
            }
          })
          this.$store.dispatch('SpecialQuantity', this.actMeun)
        })
    },
    goDetails(item) {
      let label
      if (this.actMeun == 18) {
        label = this.$t('ENU_ORDER_T_18')
      } else {
        label =this.$t('ENU_SPECIAL_A_19')
      }
      this.$router.push({
        path: '/goodsDetails',
        query: {
          waresCode: item.waresCode,
          specialArea: this.actMeun,
          label: label,
        },
      })
    },
    addToCar(ev, item) {
      // closePopover
      this.$refs.sideBar.closePopover()
      let carList = {
        specialArea: item.specialArea,
        number: 1,
        waresCode: item.waresCode,
        productGroup: item.productGroup,
      }
      api.addShopping(carList).then((res) => {
        if (res.code == 200) {
          this.$store.dispatch('SpecialQuantity',item.specialArea)
          this.$message({
            message:this.$t('w_0036'),
            type: 'success',
          })
        }
      })
      this.isClick = item.waresCode
      let that = this
      setTimeout(() => {
        that.isClick = ''
      }, 60)
      this.$refs.ball.action(ev.target).then(() => {})
    },
    // 此处为使用入口
    action(ev) {
      this.$refs.ball.action(ev.target).then(() => {
        this.heartBeat = true
      })
    },
    afterEnter() {
      console.log('成功加入购物车!')
    },
  },
}
</script>

<style lang="scss" scoped>
.bartopbox {
  margin-top: 20px;
  .flexbox {
    align-items: center;
    display: flex;
  }
  .levelList_i {
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    padding: 0 5px;
    min-width: 110px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: center;
    line-height: 44px;
  }
}
.act {
  color: #ffffff;
  background: rgba(192, 31, 31, 1);
}
.main {
  min-height: 100vh;
  display: flex;
  background: #f8f8f8;
  //padding-top: 50px;
  .main_l {
    width: 380px;
    background: #ffffff;
    box-shadow: 5px 5px 20px 0px rgba(233, 233, 233, 0.5);
    border-radius: 10px 10px 10px 10px;
  }
  .main_r {
    flex: 1;
    padding: 0 60px 0 0px;
    .banner {
      width: 1458px;
      height: 253px;
    }
    .menuList {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .menuList_i {
        width: 110px;
        height: 44px;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        border: 1px solid #999999;
        line-height: 44px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        cursor: pointer;
        margin-right: 20px;
      }
      .actMeun {
        background: #d61820;
        border: 1px solid #d61820;
        color: #fff;
      }
    }
  }
}
.goodList {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  padding: 40px 40px;
  flex-wrap: wrap;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-top: 20px;
  .good_i {
    position: relative;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(172, 172, 172, 0.5);
    border-radius: 10px 10px 10px 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    .goodBtm {
      width: 278px;
      min-height: 180px;
      padding: 10px 20px 0px 20px;
    }
    .cool {
      position: absolute;
      right: 0;
      img {
        width: 55px;
        height: 60px;
      }
    }
    .motai {
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      width: 318px;
      height: 318px;
      z-index: 1000;
      div {
        text-align: center;
        margin-top: 30%;
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .good_i:hover {
    transform: scale(1.1);
  }
  .goodImg {
    width: 318px;
    height: 318px;
  }
}
.addCarImg {
    // width: 279px;
    // height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e2524a;
    font-weight: 500;
    padding: 3px 0 ;
    img{
      width: 25px;
      height: 25px;
      margin-right: 10px
    };
    border: 2px solid #e2524a;
    border-radius: 5px;
}
.addCarImg1{
  display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    font-weight: 500;
    padding: 3px 0 ;
    img{
      width: 25px;
      height: 25px;
      margin-right: 10px
    };
    border: 2px solid #666;
    border-radius: 5px;
}
.md {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  // padding-bottom: 10px;
  img {
    height: 22px;
    width: auto;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
.hui {
  color: #666;
}
.hui_b {
  background: #666;
}

.myCar {
  display: flex;
  width: 168px;
  height: 32px;
  border-radius: 4px;
  border: 2px solid #d81e06;
  font-size: 23px;
  color: #d81e06;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  div {
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
  }
}
.qzbq {
  background: #d61820;
  border-radius: 2px 2px 2px 2px;
  font-size: 10px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  padding: 0px 5px;
  margin-right: 5px;
}
.disFlex {
  width: 278px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.tit1 {
  font-size: 20px;
  font-weight: 600;
  color: #b42b2a;
  margin-right: 10px;
  margin-bottom: 8px;
}
.tit2 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 5px;
}
.tit3 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 12px;
}
.tit4 {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-bottom: 12px;
}
.dian {
  width: 22px;
  height: 22px;
  background: #fe0000;
  border-radius: 50%;
}
.ccc {
  position: fixed;
  width: 86px;
  height: 1px;
  right: 0;
  text-align: center;
}
</style>