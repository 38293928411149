<template>
  <div class="contain">
      <div>
          <!-- 公告弹窗 -->
          <notice-popup :userInfo="userInfo" :publishLocationIndex="index"></notice-popup>
      </div>
    <sidebarUserInfo ></sidebarUserInfo>
    <div class="rightbox" style="width: 100%">
      <topBanner v-if="specialArea!=0"></topBanner>
      <template>
          <!--抗衰能量仓 !-->
          <energy-warehouse v-if="specialArea==0"/>
      </template>
        <template>
            <!--创客空间认证 !-->
            <markerSpace v-if="specialArea==1"/>
        </template>
        <template>
            <!--超级工作室认证 !-->
            <superStudio v-if="specialArea==2"/>
        </template>
        <template>
            <!--乡村振兴礼包 !-->
            <spaceMallList v-if="specialArea==18"/>
        </template>
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import energyWarehouse from "@/components/market/energyWarehouse.vue";
import spaceMallList from "@/components/market/spaceMallList.vue";
import markerSpace from "@/components/markerSpace.vue";
import superStudio from "@/components/superStudio.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import topFBar from "@/components/topFBar.vue";
import * as ene from "@/api/energy.js";
import {mapGetters} from "vuex";
import noticePopup from "@/components/noticePopup.vue";
export default {
  name: "Registration",
  components: {
    noticePopup,
    energyWarehouse,
    markerSpace,
    spaceMallList,
    superStudio,
    sidebarUserInfo,
    topBanner,
    pagination,
    topFBar,
  },
    computed: {
        ...mapGetters(['userInfo']),
    },
  data() {
    return {
        index:70,
        moren: "energyWarehouse",
      topFbarList: [
        {
          name: "抗衰能量仓",
          path: "energyWarehouse",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
      },
      giftList: [],
      smShow:false,
      isShow1:false,
      isShow2:false,
      isShow3:false,
      isShow4:false,
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    // this.research();
  },
  methods: {
    clickNum(row) {
      ene.getSiloDetail({ pkId: row.pkId }).then((res) => {
        this.giftList = res.rows;
        this.smShow = true
      });
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
      };
      this.thetime = "";
      this.research();
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      if (this.thetime) {
        this.queryParams.startDate = this.thetime[0];
        this.queryParams.endDate = this.thetime[1];
      } else {
        this.queryParams.startDate = "";
        this.queryParams.endDate = "";
      }
      ene.getSiloList({}).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
 <style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}

.waibox {
  position: relative;
  .passkh {
    width: 88px;
    height: 88px;
  }
  .poimg {
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 5px;
    right: 15px;
    color: #ffffff;
  }
}

.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 47px 62px 7px 0;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
  }
}
</style>