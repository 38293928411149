import { render, staticRenderFns } from "./spaceMallList.vue?vue&type=template&id=442a4789&scoped=true"
import script from "./spaceMallList.vue?vue&type=script&lang=js"
export * from "./spaceMallList.vue?vue&type=script&lang=js"
import style0 from "./spaceMallList.vue?vue&type=style&index=0&id=442a4789&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442a4789",
  null
  
)

export default component.exports